import { useContext } from 'react'

import { FEATURE_TYPES } from 'providers/featureFlags/featureFlagsConfig'
import { FeatureFlagsContext } from 'providers/featureFlags/FeatureFlagsContext'

export const useFlag = (flagName: FEATURE_TYPES) => {
  const { flags } = useContext(FeatureFlagsContext)

  return flags[flagName]
}
